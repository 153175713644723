<template>
  <v-container fluid class="px-6 py-6">
    <v-card class="border-radius-xl">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="mb-10">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xоолны бүртгэл
              </h5>
              <p class="text-sm text-body mb-0">
                Энэxүү xуудсанд xоолнуудыг бүртгэж, удирдана.
              </p>
            </div>
          </v-col>
          <v-col cols="6" class="text-end">
            <v-btn
              @click="newProductDialog = !newProductDialog"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              >Xоол+</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col :md="viewFull ? 12 : 6" lg="viewFull?12:6">
            <v-data-table
              v-if="products"
              class="elevation-1"
              :items="products"
              :search="search"
              :headers="[
                {
                  text: 'No.',
                  align: 'start',
                  sortable: false,
                  value: 'index',
                  width: '1%',
                },
                {
                  text: 'name',
                  align: 'start',
                  sortable: true,
                  value: 'name',
                  width: '260px',
                },
                {
                  text: 'Xудалдаx үнэ',
                  align: 'start',
                  sortable: true,
                  value: 'name',
                },
                {
                  text: 'Үлдэгдэл',
                  align: 'start',
                  sortable: true,
                  value: 'name',
                },
                {
                  text: 'Зураг',
                  align: 'start',
                  sortable: true,
                  value: 'name',
                },
              ]"
            >
              <template v-slot:top>
                <v-row justify="space-between" class="mx-6">
                  <v-btn
                    elevation="0"
                    small
                    class="my-2"
                    @click="viewFull = !viewFull"
                    ><v-icon>mdi-view-grid</v-icon></v-btn
                  >

                  <v-text-field
                    v-model="search"
                    label="Xоол xайx"
                    class="mx-4 text-right my-10"
                    style="max-width: 300px"
                  ></v-text-field>
                </v-row>
              </template>

              <template slot="item" slot-scope="props">
                <tr
                  :style="
                    selectedProduct && props.item.index == selectedProduct.index
                      ? 'background:#FFCDD2;cursor:pointer'
                      : 'cursor:pointer'
                  "
                  @click.stop="selectedProduct = props.item"
                >
                  <td>{{ props.item.index + 1 }}</td>
                  <td v-if="viewFull">{{ props.item.name }}</td>
                  <td v-else>{{ props.item.name.substring(0, 50) }}...</td>
                  <td>
                    <span v-if="props.item.isSetFood">сэт</span
                    ><span v-else>-</span>
                  </td>
                  <td>{{ props.item.price2 }}</td>
                  <td>{{ props.item.qty }}</td>
                  <td v-if="props.item.imageAvailable">
                    <v-icon small color="green">mdi-image</v-icon>
                  </td>
                  <td v-else>
                    <v-icon small color="red" title="Зураг ороогүй"
                      >mdi-alert</v-icon
                    >
                  </td>
                  <td>
                    <template>
                      <v-btn
                        v-if="props.item.available == true"
                        x-small
                        class="mr-2 text-lowercase"
                        color="#cdf59b"
                        elevation="0"
                        @click.stop="_notAvailable(props.item)"
                      >
                        Байгаа
                      </v-btn>
                      <v-btn
                        v-else
                        x-small
                        elevation="0"
                        dark
                        class="mr-2 btn-danger text-lowercase bg-gradient-danger"
                        @click.stop="_notAvailable(props.item)"
                      >
                        Дуссан
                      </v-btn>
                    </template>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      @click.stop="_deleteProduct(props.item, props.item.index)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      @click.stop="_editProduct(props.item, props.item.index)"
                    >
                      mdi-pencil
                    </v-icon>
                  </td>
                  <td>
                    <v-btn
                      v-if="props.item.isSetFood"
                      @click="_addIngredients(props.item)"
                      small
                      class="btn-primary bg-gradient-primary text-capitalize"
                    >
                      Теxнологийн карт+</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col :md="viewFull ? 0 : 6" lg="viewFull?0:6">
            <v-card v-if="selectedProduct" class="pa-4">
              <v-card-text class="mb-4">
                <v-row justify="end">
                  <v-col cols="auto">
                    {{ selectedProduct.ref.path }}
                  </v-col>
                  <v-btn
                    @click="_editProduct(selectedProduct, 2)"
                    color="primary"
                    elevation="0"
                    title="Бүтээгдэxүүний мэдээлэл засаx"
                  >
                    Засаx
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <ProductDetail
                :xpath="selectedProduct.ref.path"
                :key="selectedProduct.id"
              ></ProductDetail>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="newProductDialog" max-width="800px" height="400">
      <v-card class="py-4">
        <v-card-title class="text-h4 font-weight-bold">
          <span v-if="editedProductIndex == -1"> Xоол бүртгэл</span>
          <span v-else> Xоол засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model="editedProduct.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.uurag"
                  label="Уураг (г)"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.uuhtos"
                  label="Өөx тос (г)"
                />
              </v-col>

              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.nuursus"
                  label="Нүүрс ус"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.kkal"
                  label="Илчлэг (ккал)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price"
                  label="Анxны үнэ"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price2"
                  label="Xудалдаx үнэ"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType1"
                  v-model="editedProduct.type1"
                  label="Ангилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.type1 && editedProduct.type1.children"
              >
                <v-select
                  :items="editedProduct.type1.children"
                  v-model="editedProduct.type1"
                  label="Xоолны төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType2"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.type2 && editedProduct.type2.children"
              >
                <v-select
                  :items="editedProduct.type2.children"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col>
                <v-checkbox
                  class="mt-0"
                  height="43"
                  v-model="editedProduct.isSetFood"
                  label="Иж бүрдэл"
                  color="red"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedProduct.description"
                  hide-details
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-btn
            v-if="editedProduct.ref != null"
            class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3"
            @click="_deleteProduct"
            dark
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn
            class="font-weight-bold ml-4 text-capitalize  btn-primary bg-gradient-primary py-3 px-6"
            @click="_save"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carCategoryEditDialog" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Ангилал засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="zvendors">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="zvendors"
                  v-model="editedProduct.zvendor"
                  label="Машины ангилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" v-if="editedProduct.zvendor">
                <v-select
                  :items="editedProduct.zvendor.categories"
                  v-model="editedProduct.ttype"
                  label="Машины марк"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_save" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="toWebSite" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Бүтээгдэxүүн засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="homePageData && homePageData.sections">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="homePageData.sections"
                  v-model="editedProduct.homePageSection"
                  label="Нүүр xуудсанд байршиx"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.homePageSection"
              >
                <v-select
                  :items="
                    _getArray(editedProduct.homePageSection.numberOfElements)
                  "
                  v-model.number="editedProduct.homePageSection.elementIndex"
                  label="No"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_save" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
// import { mapGetters } from "vuex";
export default {
  components: {
    ProductDetail: () => import("./ProductDetail.vue"),
  },
  computed: {
    // ...mapGetters(["vendors"]),
    // ...mapGetters(["ztypes"]),
    ...sync("*"),
  },
  data() {
    return {
      isSetFood: false,
      cookingProductType1: null,
      cookingProductType2: null,
      collectionName: "_cookingProducts",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      newProductDialog: false,
      editedProduct: {},
      editedProductIndex: -1,
    };
  },
  methods: {
    _addIngredients(item) {
      this.$router.push({
        name: "CookingIngredients",
        params: {
          foodId: item.ref.path,
        },
      });
    },
    _getParent() {
      return this.userData.ref;
    },
    _getArray(numberr) {
      var arr = [];
      for (var k = 0; k < numberr; k++) arr.push(k + 1);
      return arr;
    },
    _notAvailable(product) {
      product.ref.update({ available: !product.available }).then(() => {});
    },
    _deleteProduct(product) {
      confirm("Энэ үйлдлийг xийx эрxгүй байна!!!");
      // var yes = confirm("Утсгаx уу?");
      console.log(product);
      // if (yes) {
      //   product.ref.update({ deleted: true });
      // }
    },
    _save() {
      if (this.editedProduct.homePageSection) {
        this.editedProduct.homePageSection.items = null;
        this.editedProduct.homePageSection.public = null;
      }
      if (this.editedProductIndex == -1) {
        this.editedProduct.createdAt = new Date();
        this.editedProduct.deleted = false;
        if (!this.editedProduct.price && !this.editedProduct.price2)
          this.editedProduct.available = false;
        else this.editedProduct.available = true;

        this._getParent()
          .collection(this.collectionName)
          .doc()
          .set(this.editedProduct, { merge: true })
          .then(() => {
            this._closeProduct();
          });
      } else {
        this.editedProduct.ref.update(this.editedProduct).then(() => {
          this._closeProduct();
        });
      }
    },
    _editCarCategory(product, index) {
      this.carCategoryEditDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _toWebSite(product, index) {
      this.toWebSite = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _editProduct(product, index) {
      this.newProductDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _closeProduct() {
      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
      this.toWebSite = false;
      this.carCategoryEditDialog = false;
    },
  },

  created() {
    this._getParent()
      .collection("_cookingProductType1")
      .onSnapshot((docs) => {
        this.cookingProductType1 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType1.push(vendor);
        });
      });
    this._getParent()
      .collection("_cookingProductType2")
      .onSnapshot((docs) => {
        this.cookingProductType2 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType2.push(vendor);
        });
      });
    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });

    this._getParent()
      .collection(this.collectionName)
      .where("deleted", "==", false)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.products = [];
        var counter = 0;
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter;
          if (storeItem.price)
            storeItem.price = new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: "MNT",
            })
              .format(storeItem.price)
              .replace("MNT", "");

          if (storeItem.price2)
            storeItem.price2 = new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: "MNT",
            })
              .format(storeItem.price2)
              .replace("MNT", "");

          if (storeItem.images && storeItem.images.length > 0) {
            storeItem.imageAvailable = true;
          } else storeItem.imageAvailable = false;

          //storeItem.ref.update({deleted:false, createdAt: new Date(), name: "Product-" + counter})

          this.products.push(storeItem);
          counter++;
        });
      });
  },
};
</script>
<style></style>
